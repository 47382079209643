import { Component, OnInit } from '@angular/core';
import { Noticia } from 'src/app/models/noticia.model';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  noticias: Noticia[] = [];
  tags: string[] = [];

  loading: boolean = false;

  page: number = 1;
  noMore: boolean = false;
  tag: string = '%';

  buscarNoticiasSubscription: Subscription;

  constructor(
    public _data: DataService
  ) { }

  ngOnInit(): void {
    this.buscarNoticias();
    this.buscarTags();
  }

  ngOnDestroy() {
    this.buscarNoticiasSubscription.unsubscribe();
  }

  buscarNoticias() {
    this.loading = true;
    this.buscarNoticiasSubscription = this._data.buscarNoticias(this.page, 3, this.tag)
      .subscribe((res: Noticia[]) => {
        if (res.length < 3) {
          this.noMore = true;
        }
        this.noticias = this.page == 1 ? res : this.noticias.concat(res);
        this.loading = false;
      }, e => this.loading = false);
  }

  buscarTags() {
    this._data.buscarTagsNoticia()
      .subscribe((res: string[]) => this.tags = res)
  }

  loadMore() {
    this.page++;
    this.buscarNoticias();
  }

  onTagChange(tag: string) {
    this.tag = tag;
    this.page = 1;
    this.noticias = [];
    this.buscarNoticias();
  }
}
