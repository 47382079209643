<section class="banner" [style.backgroundImage]="'url(' + banner.imagem + ')'">
    <div class="container">
        <div class="banner-wrapper">
            <h1 class="banner-title">
                {{banner.titulo}}
            </h1>
            <h2 class="banner-description">
                <p>{{banner.descricao}}</p>
            </h2>
            <div class="banner-buttons">
                <button class="btn btn-outlined btn-large" [ngxScrollTo]="'sobre'">
                    Conheça mais
                </button>
                <a [href]="banner.link" class="btn btn-default btn-large" *ngIf="banner.link">
                    Matricule-se
                </a>
            </div>
        </div>
    </div>
</section>

<section class="home-about" id="sobre">
    <div class="container">
        <div class="home-about-wrapper">
            <div class="home-about-content">
                <h2 class="home-about__title">
                    O Culturão
                </h2>
                <div class="home-about__description" [innerHtml]="sobre.descricao"></div>
            </div>
            <div class="home-about__image">
                <img [src]="sobre.imagem" alt="Sobre">
            </div>
        </div>
    </div>
</section>

<section class="facts" [style.backgroundImage]="'url(' + fatos.imagem + ')'">
    <div class="container">
        <!-- <h2 class="facts-title">
            Sobre o Culturão
        </h2> -->
        <div class="facts-wrapper">
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato1}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato1}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato2}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato2}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato3}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato3}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato4}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato4}}</span>
            </div>
        </div>
    </div>
</section>

<section class="testimonials">
    <div class="testimonials-title">
        <h2>Depoimentos de alunos</h2>
    </div>
    <ngu-carousel #depoimentosCarousel [inputs]="carouselBanner" [dataSource]="depoimentos"
        class="testimonials-carousel">
        <ngu-item NguCarouselItem class="testimonials-item" *nguCarouselDef="let item; let i = index">
            <div class="testimonials-item__description">
                {{item.depoimento}}
            </div>
            <div class="testimonials-item__mark">
                <i class="fas fa-quote-right"></i>
            </div>
            <div class="testimonials-item__person">
                <div class="testimonials-item__avatar">
                    <img [src]="item.foto || 'assets/placeholder-person.jpg'" [alt]="item.nome">
                </div>
                <h4 class="testimonials-item__name">
                    <strong>{{item.nome}}</strong>
                    <small>{{item.descritivo}}</small>
                </h4>
            </div>
        </ngu-item>
        <ul class="testimonials-carousel-dots" NguCarouselPoint *ngIf="depoimentos.length > 4">
            <li *ngFor="let point of depoimentosCarousel.pointNumbers; let i = index"
                [class.active]="i == depoimentosCarousel.activePoint" (click)="depoimentosCarousel.moveTo(i)"
                class="testimonials-carousel-dots__item">
            </li>
        </ul>
    </ngu-carousel>
</section>

<section class="benefits">
    <div class="container">
        <div class="benefits-wrapper">
            <div class="benefits-item" *ngFor="let b of beneficios">
                <div class="benefits-item__image">
                    <img [src]="b.icone" [alt]="b.nome">
                </div>
                <h3 class="benefits-item__title">
                    {{b.nome}}
                </h3>
            </div>
        </div>
    </div>
    <div class="benefits-image hidden-mobile">
        <img src="assets/girl.jpg" alt="Garota Culturão">
    </div>
</section>

<section class="home-events">
    <div class="container">
        <div class="title">
            <h2>Cursos</h2>
            <a routerLink="/cursos" class="btn btn-outlined btn-icon">
                <span>Ver todos</span>
                <i class="fas fa-long-arrow-alt-right"></i>
            </a>
        </div>
        <div class="events-list">
            <a *ngFor="let c of cursos; let i = index;" [routerLink]="['/cursos', c.slug]" class="events-item">
                <div class="events-item-header">
                    <div class="events-item-image">
                        <img [src]="c.imagemPequena " [alt]="c.nome">
                    </div>
                    <span class="events-item-tag">
                        {{c.tag}}
                    </span>
                </div>
                <div class="events-item-content">
                    <h3 class="events-item-title">
                        {{c.nome}}
                    </h3>
                    <!-- <p class="events-item-description">
                        {{c.descricaoSimples | slice:0:64}}{{c.descricaoSimples.length >= 64 ? '...' : ''}}
                    </p> -->
                </div>
            </a>
        </div>
    </div>
</section>

<section class="home-blog">
    <div class="container">
        <div class="title">
            <h2>Últimas notícias</h2>
            <a routerLink="/blog" class="btn btn-outlined btn-icon">
                <span>Ver todas as notícias</span>
                <i class="fas fa-long-arrow-alt-right"></i>
            </a>
        </div>
        <div class="blog-list">
            <a class="blog-item" *ngFor="let n of noticias;" [routerLink]="['/blog', n.slug]">
                <div class="blog-item-header">
                    <div class="blog-item-image">
                        <img [src]="n.imagemPequena" [alt]="n.titulo">
                    </div>
                    <span class="blog-item-tag">
                        {{n.tag}}
                    </span>
                </div>
                <div class="blog-item-content">
                    <h3 class="blog-item-title">
                        {{n.titulo}}
                    </h3>
                    <p class="blog-item-description">
                        {{n.descricaoSimples | slice:0:64}}{{n.descricaoSimples.length >= 64 ? '...' : ''}}
                    </p>
                    <div class="blog-item-link">
                        <span>acessar</span>
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>