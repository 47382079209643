export class Fato {
    id: number;
    imagem: string = '';
    fato1: string = '';
    valorFato1: string = '';
    fato2: string = '';
    valorFato2: string = '';
    fato3: string = '';
    valorFato3: string = '';
    fato4: string = '';
    valorFato4: string = '';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}