export class Banner {

    id: number;
    titulo: string = '';
    descricao: string = '';
    link: string = '';
    imagem: string = '';
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}