import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BlogComponent } from './pages/blog/blog.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { EventoInternoComponent } from './pages/evento-interno/evento-interno.component';
import { BlogInternoComponent } from './pages/blog-interno/blog-interno.component';


const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:slug', component: BlogInternoComponent },
  { path: 'cursos', component: EventosComponent },
  { path: 'cursos/:slug', component: EventoInternoComponent },
  { path: 'sobre', component: SobreComponent },
  // { path: 'em-contrucao', component: UnderConstructionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
