<app-header-interno [title]="'Cursos'"></app-header-interno>

<section class="events">
    <div class="container">
        <div class="events-list">
            <a *ngFor="let c of cursos; let i = index;" [routerLink]="['/cursos', c.slug]" class="events-item">
                <div class="events-item-header">
                    <div class="events-item-image">
                        <img [src]="c.imagemPequena " [alt]="c.nome">
                    </div>
                    <span class="events-item-tag">
                        {{c.tag}}
                    </span>
                </div>
                <div class="events-item-content">
                    <h3 class="events-item-title">
                        {{c.nome}}
                    </h3>
                    <!-- <p class="events-item-description">
                        {{c.descricaoSimples | slice:0:64}}{{c.descricaoSimples.length >= 64 ? '...' : ''}}
                    </p> -->
                </div>
            </a>
        </div>
        <div class="events-list-add" *ngIf="!noMore && !loading">
            <button class="btn btn-outlined btn-block" (click)="loadMore()">
                VER MAIS
            </button>
        </div>
        <div class="list-loading" *ngIf="loading">
            <img src="assets/loading.gif" alt="Loading..">
        </div>
    </div>
</section>