<section class="alert" [class.active]="_popup.active">
    <div class="logo">
        <img src="assets/logo.png" alt="Culturão">
    </div>
    <hr>
    <h2 class="alert__title">
        {{_popup.texto}}
    </h2>
    <button class="btn btn-default btn-medium" (click)="_popup.dismiss()">FECHAR</button>
</section>
<div class="alert-backdrop" [class.active]="_popup.active" (click)="_popup.dismiss()"></div>