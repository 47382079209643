import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Sobre } from 'src/app/models/sobre.model';
import { Fato } from 'src/app/models/fato.model';
import { Depoimento } from 'src/app/models/depoimento.model';
import { DataService } from 'src/app/services/data.service';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  sobre: Sobre = new Sobre();
  fatos: Fato = new Fato();
  depoimentos: Depoimento[] = [];
  
  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 4, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 1500,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 4,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  forkSubscription: Subscription;

  constructor(
    public _data: DataService
  ) { }

  ngOnInit(): void {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork() {

    let buscarSobre = this._data.buscarSobre();
    let buscarFatos = this._data.buscarFatos();
    let buscarDepoimentos = this._data.buscarDepoimentos(-99, -99);

    this.forkSubscription = forkJoin([
      buscarSobre,
      buscarFatos,
      buscarDepoimentos,
    ])
      .subscribe((res: any[]) => {
        this.sobre = res[0];
        this.fatos = res[1];
        this.depoimentos = res[2];
      })
  }

}
