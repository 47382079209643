import { Component, OnInit } from '@angular/core';
import { Curso } from 'src/app/models/curso.model';
import { Subscription, concat } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  cursos: Curso[] = [];

  loading: boolean = false;

  page: number = 1;
  noMore: boolean = false;

  buscarCursosSubscription: Subscription;

  constructor(
    public _data: DataService
  ) { }

  ngOnInit(): void {
    this.buscarCursos();
  }

  ngOnDestroy() {
    this.buscarCursosSubscription.unsubscribe();
  }

  buscarCursos() {
    this.loading = true;
    this.buscarCursosSubscription = this._data.buscarCursos(this.page, 9)
      .subscribe((res: Curso[]) => {
        if (res.length < 9) {
          this.noMore = true;
        }
        this.cursos = this.page == 1 ? res : this.cursos.concat(res);
        this.loading = false;
      }, e => this.loading = false);
  }

  loadMore() {
    this.page++;
    this.buscarCursos();
  }

}
