<app-header [contatoGeral]="contatoGeral"></app-header>

<main>
    <router-outlet></router-outlet>
</main>

<app-footer [contatoGeral]="contatoGeral"></app-footer>

<app-alert></app-alert>
<app-popup></app-popup>

<a class="btn-whatsapp" [href]="'https://wa.me/55'+contatoGeral.whatsApp" target="_blank">
    <i class="fab fa-whatsapp"></i>
</a>