import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  active: boolean = false;
  texto: string = '';

  constructor() { }

  present(texto: string) {
    this.texto = texto;
    this.active = true;
  }

  dismiss() {
    this.active = false;
    sessionStorage.setItem('POPUP_SHOWED', 'true');
  }
}
