import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { NgForm } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  _mensagem: ContatoMensagem = new ContatoMensagem();

  loading: boolean = false;

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    public _alert: AlertService
  ) { 
  }

  ngOnInit(): void {
    this.buscarContatoGeral();
  }

  buscarContatoGeral() {
    this._data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => this.contatoGeral = res);
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this._helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this._data.inserirMensagem(this._mensagem)
      .subscribe(res => {
        this._alert.present('Mensagem enviada', 'Em breve entraremos em contato.');
        this._mensagem.telefone = '';
        f.reset();
        this.loading = false;
      }, e => {
        this._alert.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loading = false;
      });
  }

}
