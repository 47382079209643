<section class="events-detail-header" [style.backgroundImage]="'url(' + curso.imagemGrande + ')'">
    <div class="container">
        <div class="events-detail-header-wrapper">

            <ng-container *ngIf="!hasPromotion && !curso.valor">
                <div class="events-detail-header-time">
                    <ng-container *ngIf="curso.data">
                        <i class="fas fa-clock"></i>
                        <span>{{_helper.moment(curso.data).format('DD')}} de
                            {{_helper.moment(curso.data).format('MMMM')}}</span>
                    </ng-container>
                </div>
                <h1 class="events-detail__title">
                    {{curso.nome}}
                </h1>
            </ng-container>

            <ng-container *ngIf="!hasPromotion && curso.valor">
                <div class="events-detail-header-time">
                    <ng-container *ngIf="!curso.data">
                        <i class="fas fa-graduation-cap"></i>
                        <span>{{curso.nome}}</span>
                    </ng-container>
                    <ng-container *ngIf="curso.data">
                        <i class="fas fa-clock"></i>
                        <span>{{_helper.moment(curso.data).format('DD')}} de
                            {{_helper.moment(curso.data).format('MMMM')}} -
                            {{curso.nome}}</span>
                    </ng-container>
                </div>
                <h1 class="events-detail__title">
                    <small><s>{{curso.valor | currency:'BRL':true}}</s></small>{{curso.valorPromocao | currency:'BRL':true}}<br />por
                    matrícula
                </h1>
            </ng-container>

            <ng-container *ngIf="hasPromotion && curso.valor">
                <div class="events-detail-header-time">
                    <i class="fas fa-clock"></i>
                    <span>{{_helper.moment(curso.data).format('DD')}} de
                        {{_helper.moment(curso.data).format('MMMM')}} - {{curso.nome}}</span>
                </div>
                <h1 class="events-detail__title">
                    <small><s>{{curso.valor | currency:'BRL':true}}</s></small>{{curso.valorPromocao | currency:'BRL':true}}<br />por
                    matrícula
                </h1>
                <div class="events-detail-stopwatch">
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{dias}}</h3>
                        <span>dias</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{horas}}</h3>
                        <span>horas</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{minutos}}</h3>
                        <span>minutos</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{segundos}}</h3>
                        <span>segundos</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="hasPromotion && !curso.valor">
                <div class="events-detail-header-time">
                    <i class="fas fa-clock"></i>
                    <span>{{_helper.moment(curso.data).format('DD')}} de
                        {{_helper.moment(curso.data).format('MMMM')}}</span>
                </div>
                <h1 class="events-detail__title">
                    {{curso.nome}}
                </h1>
                <div class="events-detail-stopwatch">
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{dias}}</h3>
                        <span>dias</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{horas}}</h3>
                        <span>horas</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{minutos}}</h3>
                        <span>minutos</span>
                    </div>
                    <div class="events-detail-stopwatch-item">
                        <h3 class="events-detail-stopwatch-item__number">{{segundos}}</h3>
                        <span>segundos</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<section class="events-detail">
    <div class="container">
        <div class="events-detail-wrapper">

            <div class="events-detail__image">
                <img [src]="curso.imagemPequena" [alt]="curso.nome">
            </div>

            <div class="events-detail__content">
                <h2>Sobre o curso</h2>
                <p>
                    {{curso.descricaoSimples}}
                </p>
            </div>
        </div>
    </div>
</section>

<section class="events-detail-banner">
    <div class="container">
        <h2 class="events-detail-banner__title">
            Faça agora sua matrícula!
        </h2>
        <a [href]="'https://wa.me/55'+contatoGeral.whatsApp" target="_blank" class="btn btn-large btn-white">
            Matricule-se
        </a>
    </div>
</section>

<div class="events-detail-speakers">
    <div class="container">
        <div class="title">
            <h2>Informações</h2>
        </div>
        <div class="post-content" [innerHtml]="curso.descricao"></div>
    </div>
</div>