export class ContatoGeral {

    id: number = null;
    telefoneSecretaria: string = '';
    telefoneComercial: string = '';
    telefoneFixo: string = '';
    whatsApp: string = '';
    email: string = '';
    linkFacebook: string = '';
    linkInstagram: string = '';
    linkLinkedin: string = '';
    uf: string = '';
    cidade: string = '';
    rua: string = '';
    numeroEndereco: string = '';
    bairro: string = '';
    cep: string = '';
    complemento: string = '';
    textoModal: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}