import { Injectable, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from './global.service';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  moment;
  emailRegex: RegExp;

  constructor(
    @Inject(DOCUMENT) private dom,
    public global: GlobalService,
    public title: Title,
    public meta: Meta
  ) {

    moment.locale('pt-Br');
    this.moment = moment;
    this.emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object).values(control.controls).forEach(c => this.formMarkAllTouched(c));
      }
    });
  }

  getYoutubeId(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11) ? match[2] : null;
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  renderImage(path: string, width: number): string {
    return `${this.global.apiUrl}/site/imagem/renderizar?path=${path}&width=${width}`;
  }

  renderImageHeight(path: string, height: number): string {
    return `${this.global.apiUrl}/site/imagem/renderizar?path=${path}&height=${height}`;
  }

  updateTags() {
    const res = {
      nome: 'Culturão EJA | Seu futuro começa aqui.',
      descricaoSimples: 'Faça parte da nossa história, aqui você sai na frente.',
      imagem: 'assets/avatar.png'
    }
    const shareUrl: string = `https://culturao.com.br`;
    this.title.setTitle(res.nome);
    this.meta.updateTag({ name: 'title', content: res.nome });
    this.meta.updateTag({ name: 'subject', content: res.nome });
    this.meta.updateTag({ name: 'description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'url', content: shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagem });
    this.meta.updateTag({ property: 'og:title', content: res.nome });
    this.meta.updateTag({ property: 'og:description', content: res.descricaoSimples });
    this.meta.updateTag({ property: 'og:url', content: shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:alt', content: res.nome });
    this.meta.updateTag({ name: 'twitter:title', content: res.nome });
    this.meta.updateTag({ name: 'twitter:description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagem });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagem });
  }

}