<app-header-interno [title]="noticia.titulo"></app-header-interno>

<section class="blog-interno">
    <ul class="social-sharing">
        <li class="social-sharing__item  social-sharing__item--facebook">
            <a [href]="'http://www.facebook.com/sharer.php?u=' + shareUrl" target="_blank">
                <i class="fab fa-facebook-f"></i>
            </a>
        </li>
        <li class="social-sharing__item social-sharing__item--twitter">
            <a [href]="'https://twitter.com/intent/tweet?url=' + shareUrl" target="_blank">
                <i class="fab fa-twitter"></i>
            </a>
        </li>
        <li class="social-sharing__item  social-sharing__item--linkedin">
            <a [href]="'https://www.linkedin.com/shareArticle/?mini=true&url=' + shareUrl" title="Linkedin"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
            </a>
        </li>
        <li class="social-sharing__item  social-sharing__item--whatsapp">
            <a [href]="sanitizer.bypassSecurityTrustResourceUrl('whatsapp://send?text=Se%20liga%20nessa%20publicação%20o%20Culturão!%20' + shareUrl)"
                target="_blank">
                <i class="fab fa-whatsapp"></i>
            </a>
        </li>
    </ul>
    <div class="container">
        <div class="blog-interno-wrapper">
            <div class="post">
                <div class="post-header">
                    <div class="post-tags">
                        <span class="post-tags__item">
                            {{noticia.tag}}
                        </span>
                    </div>
                    <h2 class="post-title">
                        {{noticia.titulo}}
                    </h2>
                    <div class="post-image">
                        <img [src]="noticia.imagemGrande" [alt]="noticia.titulo">
                    </div>
                    <div class="post-info">
                        <div class="post-info__item">
                            <i class="fas fa-calendar-alt"></i>
                            <span>{{_helper.moment(noticia.data).format('DD')}} de
                                {{_helper.moment(noticia.data).format('MMMM')}},
                                {{_helper.moment(noticia.data).format('YYYY')}}</span>
                        </div>
                        <div class="post-info__item">
                            <i class="fas fa-clock"></i>
                            <span>{{noticia.tempoLeitura}}</span>
                        </div>
                    </div>
                    <div class="post-content" [innerHtml]="html"></div>
                </div>
            </div>
        </div>
    </div>
</section>