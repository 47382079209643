import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
import { DataService } from 'src/app/services/data.service';
import { Curso } from 'src/app/models/curso.model';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ContatoGeral } from 'src/app/models/contato-geral.model';

@Component({
  selector: 'app-evento-interno',
  templateUrl: './evento-interno.component.html',
  styleUrls: ['./evento-interno.component.scss']
})
export class EventoInternoComponent implements OnInit, AfterViewInit {

  dias: string = '';
  horas: string = '';
  minutos: string = '';
  segundos: string = '';

  inicioDoEvento = new Date();
  fimDoEvento = new Date();

  curso: Curso = new Curso();
  buscarCursoSubscription: Subscription;

  contatoGeral: ContatoGeral = new ContatoGeral();

  hasPromotion: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public _helper: HelperService,
    public _data: DataService,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute
  ) {

    this.inicioDoEvento.setDate(this._helper.moment().format('DD'));
    this.inicioDoEvento.setMonth(this._helper.moment().format('MM'))
    this.inicioDoEvento.setFullYear(this._helper.moment().format('YYYY'));
    this.inicioDoEvento.setHours(this._helper.moment().format('HH'), 0, 0, 0);
  }

  ngOnInit(): void {
    this.buscarContatoGeral();
  }

  ngAfterViewInit() {
    this.route.params.subscribe(param => this.buscarCurso(param.slug));
  }

  buscarCurso(slug: string) {
    this.buscarCursoSubscription = this._data.buscarCurso(slug)
      .subscribe((res: Curso) => {
        this.curso = res;
        this.updateTags(res);
        this.initClock(res.data);
      })
  }

  buscarContatoGeral() {
    this._data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => this.contatoGeral = res);
  }

  initClock(data: Date) {

    this.fimDoEvento.setDate(this._helper.moment(data).format('DD'));
    this.fimDoEvento.setMonth(this._helper.moment(data).format('MM'))
    this.fimDoEvento.setFullYear(this._helper.moment(data).format('YYYY'));
    this.fimDoEvento.setHours(this._helper.moment(data).format('HH'), 0, 0, 0);

    let inicioDoEvento = this.inicioDoEvento.getTime();
    let fimDoEvento = this.fimDoEvento.getTime();

    const total = fimDoEvento - inicioDoEvento;
    if (total > 0) {
      this.hasPromotion = true;
    }

    var x = setInterval(() => {
      var now = new Date().getTime();

      if (inicioDoEvento - now < 0) { // Se o evento já começou
        inicioDoEvento = fimDoEvento;
      }
      if (fimDoEvento - now < 0) { // Se o evento já terminou
        clearInterval(x);
        return;
      }

      const distancia = inicioDoEvento - now;
      const falta = (fimDoEvento - inicioDoEvento) / 1000;
      const dias = Math.round(falta / 60 / 60 / 24);
      const horas = Math.round(falta / 60 / 60 % 24);
      const minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((distancia % (1000 * 60)) / 1000);

      this.dias = dias.toString();
      this.horas = horas.toString();
      this.minutos = minutos < 10 ? '0' + minutos : minutos.toString();
      this.segundos = segundos < 10 ? '0' + segundos : segundos.toString();
    }, 0);
  }

  updateTags(res: Curso) {
    const shareUrl: string = `https://culturao.com.br/curso/${res.slug}`;
    this.title.setTitle(`${res.nome} - Culturão`);
    this.meta.updateTag({ name: 'title', content: `${res.nome} - Culturão` });
    this.meta.updateTag({ name: 'subject', content: `${res.nome} - Culturão` });
    this.meta.updateTag({ name: 'description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'url', content: shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:title', content: `${res.nome} - Culturão` });
    this.meta.updateTag({ property: 'og:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ property: 'og:url', content: shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:alt', content: `${res.nome} - Culturão` });
    this.meta.updateTag({ name: 'twitter:title', content: `${res.nome} - Culturão` });
    this.meta.updateTag({ name: 'twitter:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagemPequena });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagemPequena });
  }
}
