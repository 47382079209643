import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title, Meta, SafeHtml } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Noticia } from 'src/app/models/noticia.model';

@Component({
  selector: 'app-blog-interno',
  templateUrl: './blog-interno.component.html',
  styleUrls: ['./blog-interno.component.scss']
})
export class BlogInternoComponent implements OnInit {

  shareUrl: string = 'https://culturao.com.br';

  noticia: Noticia = new Noticia();
  buscarNoticiaSubscription: Subscription;

  html: SafeHtml;

  constructor(
    public sanitizer: DomSanitizer,
    public title: Title,
    public meta: Meta,
    public _data: DataService,
    public _helper: HelperService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(param => this.buscarNoticia(param.slug));
  }

  ngOnDestroy() {
    this.buscarNoticiaSubscription.unsubscribe();
    this._helper.updateTags();
  }

  buscarNoticia(slug: string) {
    this.buscarNoticiaSubscription = this._data.buscarNoticia(slug)
      .subscribe((res: Noticia) => {
        this.noticia = res;
        this.updateTags(res);
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
      })
  }

  updateTags(res: Noticia) {
    const shareUrl: string = `https://culturao.com.br/blog/${res.slug}`;
    this.title.setTitle(`${res.titulo} - Culturão`);
    this.meta.updateTag({ name: 'title', content: `${res.titulo} - Culturão` });
    this.meta.updateTag({ name: 'subject', content: `${res.titulo} - Culturão` });
    this.meta.updateTag({ name: 'description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'url', content: shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:title', content: `${res.titulo} - Culturão` });
    this.meta.updateTag({ property: 'og:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ property: 'og:url', content: shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:alt', content: `${res.titulo} - Culturão` });
    this.meta.updateTag({ name: 'twitter:title', content: `${res.titulo} - Culturão` });
    this.meta.updateTag({ name: 'twitter:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagemPequena });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagemPequena });
  }

}
