<app-header-interno [title]="'Sobre'"></app-header-interno>

<section class="about">
    <div class="container">
        <div class="about-wrapper">
            <div class="about-content">
                <h2 class="about__title">
                    O Culturão
                </h2>
                <div class="about__description" [innerHtml]="sobre.descricao"></div>
            </div>
            <div class="about__image">
                <img [src]="sobre.imagem" alt="Sobre">
            </div>
        </div>
    </div>
</section>

<section class="facts" [style.backgroundImage]="'url(' + fatos.imagem + ')'">
    <div class="container">
        <h2 class="facts-title">
            Sobre o Culturão
        </h2>
        <div class="facts-wrapper">
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato1}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato1}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato2}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato2}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato3}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato3}}</span>
            </div>
            <div class="facts-item">
                <h3 class="facts-item__number">
                    <i class="fas fa-check"></i>
                    <span>{{fatos.valorFato4}}</span>
                </h3>
                <span class="facts-item__title">{{fatos.fato4}}</span>
            </div>
        </div>
    </div>
</section>

<section class="testimonials">
    <div class="testimonials-title">
        <h2>Depoimentos de alunos</h2>
    </div>
    <ngu-carousel #depoimentosCarousel [inputs]="carouselBanner" [dataSource]="depoimentos"
        class="testimonials-carousel">
        <ngu-item NguCarouselItem class="testimonials-item" *nguCarouselDef="let item; let i = index">
            <div class="testimonials-item__description">
                {{item.depoimento}}
            </div>
            <div class="testimonials-item__mark">
                <i class="fas fa-quote-right"></i>
            </div>
            <div class="testimonials-item__person">
                <div class="testimonials-item__avatar">
                    <img [src]="item.foto || 'assets/placeholder-person.jpg'" [alt]="item.nome">
                </div>
                <h4 class="testimonials-item__name">
                    <strong>{{item.nome}}</strong>
                    <small>{{item.descritivo}}</small>
                </h4>
            </div>
        </ngu-item>
        <ul class="testimonials-carousel-dots" NguCarouselPoint *ngIf="depoimentos.length > 4">
            <li *ngFor="let point of depoimentosCarousel.pointNumbers; let i = index"
                [class.active]="i == depoimentosCarousel.activePoint" (click)="depoimentosCarousel.moveTo(i)"
                class="testimonials-carousel-dots__item">
            </li>
        </ul>
    </ngu-carousel>
</section>