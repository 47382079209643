import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ContatoMensagem } from '../models/contato-mensagem.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService
  ) { }

  buscarContatoGeral(): Observable<any> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarCadastroGeral(): Observable<any> {
    return this.api.get('/site/cadastroGeral/buscar');
  }

  buscarSobre(): Observable<any> {
    return this.api.get(`/site/sobre/buscar`);
  }

  buscarBanner() {
    return this.api.get(`/site/banner/buscar`);
  }

  buscarFatos() {
    return this.api.get(`/site/fato/buscar`);
  }

  buscarBeneficios(numeroPagina: number, registroPorPagina: number) {
    return this.api.get(`/site/beneficio/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`)
  }

  buscarDepoimentos(numeroPagina: number, registroPorPagina: number) {
    return this.api.get(`/site/depoimento/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`)
  }

  buscarCursos(numeroPagina: number, registroPorPagina: number, tag: string = '%'): Observable<any> {
    return this.api.get(`/site/curso/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&tag=${tag}`);
  }

  buscarUltimasCursos(numeroPagina: number, registrosPorPagina: number, idCurso: number, tag: string = '%'): Observable<any> {
    return this.api.get(`/site/curso/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idCurso=${idCurso}&tag=${tag}`)
  }

  buscarCurso(slug: string) {
    return this.api.get(`/site/curso/buscar?slug=${slug}`);
  }

  buscarTagsCurso(): Observable<any> {
    return this.api.get(`/site/curso/tag`);
  }

  buscarNoticias(numeroPagina: number, registroPorPagina: number, tag: string = '%'): Observable<any> {
    return this.api.get(`/site/noticia/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&tag=${tag}`);
  }

  buscarNoticia(slug: string): Observable<any> {
    return this.api.get(`/site/noticia/buscar?slug=${slug}`);
  }

  inserirMensagem(msg: ContatoMensagem) {
    return this.api.post(`/site/contatoMensagem/inserir`, msg);
  }

  buscarUltimasNoticias(numeroPagina: number, registrosPorPagina: number, idNoticia: number, tag: string = '%'): Observable<any> {
    return this.api.get(`/site/noticia/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idNoticia=${idNoticia}&tag=${tag}`)
  }

  buscarTagsNoticia(): Observable<any> {
    return this.api.get(`/site/noticia/tag`);
  }

}
