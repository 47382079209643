<app-header-interno [title]="'Blog'"></app-header-interno>

<section class="blog">
    <div class="container">
        <div class="blog-wrapper">
            <div class="left">
                <div class="blog-list">
                    <a class="blog-item blog-item--full" *ngFor="let n of noticias;" [routerLink]="['/blog', n.slug]">
                        <div class="blog-item-header">
                            <div class="blog-item-image">
                                <img [src]="n.imagemPequena" [alt]="n.titulo">
                            </div>
                            <span class="blog-item-tag" *ngIf="n.tag">
                                {{n.tag}}
                            </span>
                        </div>
                        <div class="blog-item-content">
                            <h3 class="blog-item-title">
                                {{n.titulo}}
                            </h3>
                            <p class="blog-item-description">
                                {{n.descricaoSimples | slice:0:96}}{{n.descricaoSimples.length >= 96 ? '...' : ''}}
                            </p>
                            <div class="blog-item-link">
                                <span>acessar</span>
                                <i class="fas fa-long-arrow-alt-right"></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="blog-list-add" *ngIf="!noMore && !loading">
                    <button class="btn btn-outlined btn-block" (click)="loadMore()">
                        VER MAIS
                    </button>
                </div>
                <div class="list-loading" *ngIf="loading">
                    <img src="assets/loading.gif" alt="Loading..">
                </div>
            </div>
            <div class="right">
                <div class="blog-filters">
                    <div class="blog-filters-item">
                        <h3>Categorias</h3>
                        <ul>
                            <li [class.active]="tag === '%'">
                                <a (click)="onTagChange('%')">
                                    <span>Todos</span>
                                </a>
                            </li>
                            <li *ngFor="let t of tags" [class.active]="tag === t">
                                <a (click)="onTagChange(t)">
                                    <span>{{t}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>