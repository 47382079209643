import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.culturao.com.br';
  // apiUrl: string = 'http://200.98.201.140:9014';

  constructor() { }
}
