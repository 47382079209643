<header class="header" [class.active]="active">
    <div class="header-top">
        <div class="container">
            <div class="header-top-wrapper">
                <ul class="header-contacts">
                    <li class="header-contacts__item" *ngIf="contatoGeral.email">
                        <a [href]="'mailto:'+contatoGeral.email" class="header-contacts__link">
                            <i class="fas fa-envelope"></i>
                            <span>{{contatoGeral.email}}</span>
                        </a>
                    </li>
                    <li class="header-contacts__item">
                        <a [href]="'https://wa.me/55'+contatoGeral.telefoneSecretaria" class="header-contacts__link"
                            target="_blank">
                            <i class="fab fa-whatsapp"></i>
                            <span>
                                <ng-container *ngIf="contatoGeral.telefoneSecretaria.length == 11">
                                    {{contatoGeral.telefoneSecretaria | mask: '(00) 0 0000-0000'}}
                                </ng-container>
                                <ng-container *ngIf="contatoGeral.telefoneSecretaria.length < 11">
                                    {{contatoGeral.telefoneSecretaria | mask: '(00) 0000-0000'}}
                                </ng-container>
                                (Secretaria)
                            </span>
                        </a>
                    </li>

                    <li class="header-contacts__item">
                        <a [href]="'https://wa.me/55'+contatoGeral.telefoneComercial" class="header-contacts__link"
                            target="_blank">
                            <i class="fab fa-whatsapp"></i>
                            <span>
                                <ng-container *ngIf="contatoGeral.telefoneComercial.length == 11">
                                    {{contatoGeral.telefoneComercial | mask: '(00) 0 0000-0000'}}
                                </ng-container>
                                <ng-container *ngIf="contatoGeral.telefoneComercial.length < 11">
                                    {{contatoGeral.telefoneComercial | mask: '(00) 0000-0000'}}
                                </ng-container>
                                (Comercial)
                            </span>
                        </a>
                    </li>

                    <li class="header-contacts__item">
                        <a [href]="'tel:'+contatoGeral.telefoneFixo" class="header-contacts__link"
                            target="_blank">
                            <i class="fas fa-phone-alt"></i>
                            <span>
                                <ng-container *ngIf="contatoGeral.telefoneFixo.length == 11">
                                    {{contatoGeral.telefoneFixo | mask: '(00) 0 0000-0000'}}
                                </ng-container>
                                <ng-container *ngIf="contatoGeral.telefoneFixo.length < 11">
                                    {{contatoGeral.telefoneFixo | mask: '(00) 0000-0000'}}
                                </ng-container>
                                (Fixo)
                            </span>
                        </a>
                    </li>
                </ul>
                <ul class="header-social">
                    <li class="header-social__item" *ngIf="contatoGeral.linkFacebook">
                        <a [href]="contatoGeral.linkFacebook" target="_blank" class="header-social__link">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="contatoGeral.linkInstagram">
                        <a [href]="contatoGeral.linkInstagram" target="_blank" class="header-social__link">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="contatoGeral.linkLinkedin">
                        <a [href]="contatoGeral.linkLinkedin" target="_blank" class="header-social__link">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="contatoGeral.whatsApp">
                        <a [href]="'https://wa.me/'+contatoGeral.whatsApp" target="_blank" class="header-social__link">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="header-bottom">
        <div class="container">
            <nav class="header-nav">
                <a class="logo" routerLink="/">
                    <img src="assets/logo.png" alt="Culturão EJA" title="Culturão EJA">
                </a>
                <button class="header-menu-toggle hidden-desktop" (click)="openMenu = true">
                    <i class="fas fa-bars"></i>
                </button>
                <ul class="header-menu" [class.active]="openMenu">
                    <li class="header-menu__item">
                        <a routerLink="/inicio" class="header-menu__link" title="Início" routerLinkActive="active"
                            (click)="openMenu = false">
                            Início
                        </a>
                    </li>
                    <li class="header-menu__item">
                        <a routerLink="/sobre" class="header-menu__link" title="Sobre" routerLinkActive="active"
                            (click)="openMenu = false">
                            Sobre
                        </a>
                    </li>
                    <li class="header-menu__item">
                        <a routerLink="/cursos" class="header-menu__link" title="Cursos" routerLinkActive="active"
                            (click)="openMenu = false">
                            Cursos
                        </a>
                    </li>
                    <li class="header-menu__item">
                        <a routerLink="/blog" class="header-menu__link" title="Blog" routerLinkActive="active"
                            (click)="openMenu = false">
                            Blog
                        </a>
                    </li>
                    <li class="header-menu__item">
                        <a routerLink="/contato" class="header-menu__link" title="Contato" routerLinkActive="active"
                            (click)="openMenu = false">
                            Contato
                        </a>
                    </li>
                    <li class="header-menu__item">
                        <a class="btn btn-outlined btn-icon" href="http://portal.culturao.com.br/culturao"
                            target="_blank">
                            <i class="fas fa-lock"></i>
                            <span>ÁREA RESTRITA</span>
                        </a>
                    </li>
                </ul>
                <div class="header-menu-backdrop hidden-desktop" [class.active]="openMenu" (click)="openMenu = false">
                </div>
            </nav>
        </div>
    </div>
</header>