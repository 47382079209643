import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NguCarouselModule } from '@ngu/carousel';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
// components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderInternoComponent } from './components/header-interno/header-interno.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BlogComponent } from './pages/blog/blog.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { BlogInternoComponent } from './pages/blog-interno/blog-interno.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { AlertComponent } from './components/alert/alert.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { EventoInternoComponent } from './pages/evento-interno/evento-interno.component';
import { PopupComponent } from './components/popup/popup.component';
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    // components
    HeaderComponent,
    HeaderInternoComponent,
    FooterComponent,
    AlertComponent,
    PopupComponent,
    // pages
    HomeComponent,
    ContatoComponent,
    BlogComponent,
    BlogInternoComponent,
    EventosComponent,
    EventoInternoComponent,
    SobreComponent,
    UnderConstructionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NguCarouselModule,
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    NgxPhoneMaskBrModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
