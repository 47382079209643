<app-header-interno [title]="'Entre em contato'"></app-header-interno>

<section class="contact">
    <div class="container">
        <div class="contact-wrapper">
            <div class="contact-info">
                <h2 class="contact-info__title">
                    Informações para <strong>contato</strong>
                </h2>
                <div class="contact-info-item">
                    <div class="contact-info-item__icon">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="contact-info-item__content">
                        <h3 class="contact-info-item__title">
                            Fixo
                        </h3>
                        <a [href]="'tel:'+contatoGeral.telefoneFixo" target="_blank">
                            <ng-container *ngIf="contatoGeral.telefoneFixo.length < 11">
                                {{contatoGeral.telefoneFixo | mask: '(00) 0000-0000'}}
                            </ng-container>
                            <ng-container *ngIf="contatoGeral.telefoneFixo.length >= 11">
                                {{contatoGeral.telefoneFixo | mask: '(00) 0 0000-0000'}}
                            </ng-container>
                        </a>
                    </div>
                </div>
                <div class="contact-info-item">
                    <div class="contact-info-item__icon">
                        <i class="fab fa-whatsapp"></i>
                    </div>
                    <div class="contact-info-item__content">
                        <h3 class="contact-info-item__title">
                            Secretaria
                        </h3>
                        <a [href]="'https://wa.me/55'+contatoGeral.telefoneSecretaria" target="_blank">
                            <ng-container *ngIf="contatoGeral.telefoneSecretaria.length < 11">
                                {{contatoGeral.telefoneSecretaria | mask: '(00) 0000-0000'}}
                            </ng-container>
                            <ng-container *ngIf="contatoGeral.telefoneSecretaria.length >= 11">
                                {{contatoGeral.telefoneSecretaria | mask: '(00) 0 0000-0000'}}
                            </ng-container>
                        </a>
                    </div>
                </div>
                <div class="contact-info-item">
                    <div class="contact-info-item__icon">
                        <i class="fab fa-whatsapp"></i>
                    </div>
                    <div class="contact-info-item__content">
                        <h3 class="contact-info-item__title">
                            Comercial
                        </h3>
                        <a [href]="'https://wa.me/55'+contatoGeral.telefoneComercial" target="_blank">
                            <ng-container *ngIf="contatoGeral.telefoneComercial.length < 11">
                                {{contatoGeral.telefoneComercial | mask: '(00) 0000-0000'}}
                            </ng-container>
                            <ng-container *ngIf="contatoGeral.telefoneComercial.length >= 11">
                                {{contatoGeral.telefoneComercial | mask: '(00) 0 0000-0000'}}
                            </ng-container>
                        </a>
                    </div>
                </div>
                <div class="contact-info-item">
                    <div class="contact-info-item__icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="contact-info-item__content">
                        <h3 class="contact-info-item__title">
                            E-mail
                        </h3>
                        <a [href]="'mailto:'+contatoGeral.email">{{contatoGeral.email}}</a>
                    </div>
                </div>
                <div class="contact-info-item">
                    <div class="contact-info-item__icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="contact-info-item__content">
                        <h3 class="contact-info-item__title">
                            Endereço
                        </h3>
                        <!-- Rua Marechal Floriano, 889 - Caxias do sul - RS - CEP: 95020-370 -->
                        <p *ngIf="contatoGeral.complemento">
                            {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}} - {{contatoGeral.complemento}} -
                            {{contatoGeral.cidade}} - {{contatoGeral.uf}} - CEP:
                            {{contatoGeral.cep | mask: '00000-000'}}
                        </p>
                        <p *ngIf="!contatoGeral.complemento">
                            {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}} -
                            {{contatoGeral.cidade}} - {{contatoGeral.uf}} - CEP:
                            {{contatoGeral.cep | mask: '00000-000'}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="contact-form">
                <p>
                    Preencha o formulário e nos mande uma mensagem. <b>Nós adoraríamos saber o que você está
                        pensando!</b>
                </p>
                <form class="form" #form="ngForm" (ngSubmit)="submit(form)">
                    <div class="form-control">
                        <input type="text" name="nome" id="nome" placeholder="Digite seu nome" autocomplete="name"
                            [(ngModel)]="_mensagem.nome" #nome="ngModel" required [disabled]="loading">
                        <span class="form-control__error" *ngIf="nome.invalid && (nome.touched || nome.dirty)">
                            *Preencha o nome para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <input type="email" name="email" id="email" placeholder="exemplo@email.com" autocomplete="email"
                            [(ngModel)]="_mensagem.email" #email="ngModel" required [pattern]="_helper.emailRegex"
                            [disabled]="loading">
                        <span class="form-control__error" *ngIf="email.invalid && (email.touched || email.dirty)">
                            *Preencha o e-mail para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <input type="tel" name="telefone" id="telefone" placeholder="(00) 00000.0000" autocomplete="tel"
                            [(ngModel)]="_mensagem.telefone" ngxPhoneMaskBr #telefone="ngModel" required
                            [disabled]="loading">
                        <span class="form-control__error"
                            *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)">
                            *Preencha o telefone para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" id="mesangem" placeholder="Digite sua mensagem"
                            [(ngModel)]="_mensagem.mensagem" #mensagem="ngModel" required
                            [disabled]="loading"></textarea>
                        <span class="form-control__error"
                            *ngIf="mensagem.invalid && (mensagem.touched || mensagem.dirty)">
                            *Escreva uma mensagem para enviar o formulário
                        </span>
                    </div>
                    <button class="btn btn-white" type="submit">
                        <span *ngIf="!loading">Enviar</span>
                        <img src="assets/loading.gif" alt="loading" *ngIf="loading">
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>