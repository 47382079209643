<footer class="footer">
  <div class="container">
    <div class="footer-wrapper">
      <div class="col">
        <div class="logo">
          <img src="assets/logo.png" alt="Culturão" />
        </div>
      </div>
      <div class="col hidden-mobile">
        <h3 class="footer-title">Nossas páginas</h3>
        <ul class="footer-menu">
          <li class="footer-menu__item">
            <a routerLink="/" class="footer-menu__link" title="Início">
              Início
            </a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="/sobre" class="footer-menu__link" title="Sobre">
              Sobre
            </a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="/cursos" class="footer-menu__link" title="Cursos">
              Cursos
            </a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="/blog" class="footer-menu__link" title="Blog">
              Blog
            </a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="contato" class="footer-menu__link" title="Contato">
              Contato
            </a>
          </li>
        </ul>
      </div>
      <div class="col">
        <h3 class="footer-title">Endereço</h3>
        <p *ngIf="contatoGeral.complemento">
          {{ contatoGeral.rua }}, {{ contatoGeral.numeroEndereco }} -
          {{ contatoGeral.complemento }} - {{ contatoGeral.cidade }} -
          {{ contatoGeral.uf }} - CEP:
          {{ contatoGeral.cep | mask: "00000-000" }}
        </p>
        <p *ngIf="!contatoGeral.complemento">
          {{ contatoGeral.rua }}, {{ contatoGeral.numeroEndereco }} -
          {{ contatoGeral.cidade }} - {{ contatoGeral.uf }} - CEP:
          {{ contatoGeral.cep | mask: "00000-000" }}
        </p>
      </div>
      <div class="col">
        <h3 class="footer-title">Entre em contato</h3>
        <ul class="footer-contacts">
          <li class="footer-contacts__item">
            <a
              [href]="'mailto:' + contatoGeral.email"
              class="footer-contacts__link"
            >
              <i class="fas fa-envelope"></i>
              <span>{{ contatoGeral.email }}</span>
            </a>
          </li>
          <li
            class="footer-contacts__item"
            *ngIf="contatoGeral.telefoneSecretaria"
          >
            <a
              [href]="'https://wa.me/55' + contatoGeral.telefoneSecretaria"
              class="footer-contacts__link"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
              <span>
                <ng-container
                  *ngIf="contatoGeral.telefoneSecretaria.length == 11"
                >
                  {{
                    contatoGeral.telefoneSecretaria | mask: "(00) 0 0000-0000"
                  }}
                </ng-container>
                <ng-container
                  *ngIf="contatoGeral.telefoneSecretaria.length < 11"
                >
                  {{ contatoGeral.telefoneSecretaria | mask: "(00) 0000-0000" }}
                </ng-container>
                (Secretaria)
              </span>
            </a>
          </li>

          <li
            class="footer-contacts__item"
            *ngIf="contatoGeral.telefoneComercial"
          >
            <a
              [href]="'https://wa.me/55' + contatoGeral.telefoneComercial"
              class="footer-contacts__link"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
              <span>
                <ng-container
                  *ngIf="contatoGeral.telefoneComercial.length == 11"
                >
                  {{
                    contatoGeral.telefoneComercial | mask: "(00) 0 0000-0000"
                  }}
                </ng-container>
                <ng-container
                  *ngIf="contatoGeral.telefoneComercial.length < 11"
                >
                  {{ contatoGeral.telefoneComercial | mask: "(00) 0000-0000" }}
                </ng-container>
                (Comercial)
              </span>
            </a>
          </li>

          <li class="footer-contacts__item">
            <a
              [href]="'tel:' + contatoGeral.telefoneFixo"
              class="footer-contacts__link"
              target="_blank"
            >
              <i class="fas fa-phone-alt"></i>
              <span>
                <ng-container *ngIf="contatoGeral.telefoneFixo.length == 11">
                  {{ contatoGeral.telefoneFixo | mask: "(00) 0 0000-0000" }}
                </ng-container>
                <ng-container *ngIf="contatoGeral.telefoneFixo.length < 11">
                  {{ contatoGeral.telefoneFixo | mask: "(00) 0000-0000" }}
                </ng-container>
                (Fixo)
              </span>
            </a>
          </li>
        </ul>

        <ul class="footer-social">
          <li class="footer-social__item" *ngIf="contatoGeral.linkFacebook">
            <a
              [href]="contatoGeral.linkFacebook"
              target="_blank"
              class="footer-social__link"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="footer-social__item" *ngIf="contatoGeral.linkInstagram">
            <a
              [href]="contatoGeral.linkInstagram"
              target="_blank"
              class="footer-social__link"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li class="footer-social__item" *ngIf="contatoGeral.linkLinkedin">
            <a
              [href]="contatoGeral.linkLinkedin"
              target="_blank"
              class="footer-social__link"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li class="footer-social__item" *ngIf="contatoGeral.whatsApp">
            <a
              [href]="'https://wa.me/' + contatoGeral.whatsApp"
              target="_blank"
              class="footer-social__link"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <span class="footer-copyrights">
        Copyright &copy; Culturão 2020. All rights reserved.
      </span>
      <div class="footer-devs">
        <a
          href="https://yourway.digital"
          target="_blank"
          class="footer-devs__item"
          title="YourWay Digital"
        >
          <img
            src="assets/yourway.png"
            alt="YourWay Digital"
            title="YourWay Digital"
          />
        </a>
      </div>
    </div>
  </div>
</footer>
