import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Banner } from 'src/app/models/banner.model';
import { Subscription, forkJoin } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Noticia } from 'src/app/models/noticia.model';
import { Curso } from 'src/app/models/curso.model';
import { Depoimento } from 'src/app/models/depoimento.model';
import { Fato } from 'src/app/models/fato.model';
import { Sobre } from 'src/app/models/sobre.model';
import { Beneficio } from 'src/app/models/beneficio.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  banner: Banner = new Banner();
  sobre: Sobre = new Sobre();
  fatos: Fato = new Fato();
  depoimentos: Depoimento[] = [];
  beneficios: Beneficio[] = [];
  cursos: Curso[] = [];
  noticias: Noticia[] = [];

  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 4, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 1500,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 4,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  forkSubscription: Subscription;

  constructor(
    public _data: DataService
  ) { }

  ngOnInit(): void {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork() {

    let buscarBanner = this._data.buscarBanner();
    let buscarSobre = this._data.buscarSobre();
    let buscarFatos = this._data.buscarFatos();
    let buscarDepoimentos = this._data.buscarDepoimentos(-99, -99);
    let buscarBeneficios = this._data.buscarBeneficios(-99, -99);
    let buscarCursos = this._data.buscarCursos(1, 3);
    let buscarNoticias = this._data.buscarNoticias(1, 3);

    this.forkSubscription = forkJoin([
      buscarBanner,
      buscarSobre,
      buscarFatos,
      buscarDepoimentos,
      buscarBeneficios,
      buscarCursos,
      buscarNoticias
    ])
      .subscribe((res: any[]) => {
        this.banner = res[0];
        this.sobre = res[1];
        this.fatos = res[2];
        this.depoimentos = res[3];
        this.beneficios = res[4];
        this.cursos = res[5];
        this.noticias = res[6];
      })
  }
}
