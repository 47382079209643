import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ContatoGeral } from './models/contato-geral.model';
import { CadastroGeral } from './models/cadastro-geral.model';
import { DataService } from './services/data.service';
import { HelperService } from './services/helper.service';
import { PopupService } from './services/popup.service';
import { AlertService } from './services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.keyCode;
    if (keyPressed === 27) {
      if (this._popup.active) {
        this._popup.dismiss();
      }
      if (this._alert.active) {
        this._alert.dismiss();
      }
    }
  }

  contatoGeral: ContatoGeral = new ContatoGeral();
  cadastroGeral: CadastroGeral = new CadastroGeral();

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    public _popup: PopupService,
    public _alert: AlertService
  ) {
    // const POPUP_SHOWED = sessionStorage.getItem('POPUP_SHOWED');
    // if (!POPUP_SHOWED) {

    // }
  }

  ngOnInit(): void {
    this.buscarCadastroGeral();
    this.buscarContatoGeral();
    this._helper.updateTags();
  }

  ngAfterViewInit() {

  }

  buscarCadastroGeral() {
    this._data.buscarCadastroGeral()
      .subscribe((res: CadastroGeral) => this.cadastroGeral = res);
  }

  buscarContatoGeral() {
    this._data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
        const POPUP_SHOWED = sessionStorage.getItem('POPUP_SHOWED');
        if (!POPUP_SHOWED && res.textoModal.length) {
          this._popup.present(res.textoModal);
        }
      });
  }
}
