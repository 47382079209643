export class Curso {
    id: any;
    nome: string = '';
    descricaoSimples: string = '';
    descricao: string = '';
    slug: string = '';
    tag: string = '';
    data: Date = new Date();
    valor: number = 0;
    valorPromocao: number = 0;
    imagemPequena: string = '';
    imagemGrande: string = '';
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}