export class ContatoMensagem {

    id: number;
    nome: string = '';
    email: string = '';
    telefone: string = '';
    estilo: string = '';
    mensagem: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}