export class CadastroGeral {

    id: number;
    imagem: string = '';
    titulo: string;
    descricao: string;
    palavrasChave?: string = '';
    codigoAdWords?: string = '';
    codigoHeadTagManager?: string = '';
    codigoBodyTagManager?: string = '';
    cor: string = '';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}